window.initAmap = () => {
	// $("#a").attr("src","https://webapi.amap.com/maps?v=2.0&key=19f93ce96f71a94a0f260817fe096350")
	// $("#b").attr("src","https://webapi.amap.com/loca?v=2.0.0&key=19f93ce96f71a94a0f260817fe096350")
	// $("#c").attr("src","")
	// $("#d").attr("src","")
	// $("#e").attr("src","")
	const ZOOM = 1; //设置地图层级
	var adCode = 330600; //绍兴市区域代码
	var depth = 2; //显示市等级
	var disProvince; // 创建省份图层
	var colors = {}; // 颜色辅助方法
	var shaoxingJson = './data/shaoxing.json';
	var cityJson ='https://zhipao.oss-cn-hangzhou.aliyuncs.com/models/shaoxingGeo.json';
	// var cityJson ='https://zhipao.oss-cn-hangzhou.aliyuncs.com/models/shaoxingGeo2.json';
	var lineJson = './data/shaoxing-line.json';
	var cityImage = './images/bq2.jpg';
	var markerImg = './images/marker7.gif';
	var markerbottomImg = './images/marker-bottom.gif';
	var buildingZooms = [10, 20]; // 设置层级范围 显示3D 绍兴[楼块] 显示层级区间
	var regionBlockZooms = [1, 10]; // 设置层级范围 绍兴市区域视图 显示层级区间
	var map
	window.map = map = new AMap.Map('amap', {
		viewMode: '3D', //设置3D地图
		zoom: ZOOM, //地图层级
		zooms: [9.6, 20],
		resizeEnable: true, // 设置地图自适应
		//rotation: -35, //默认地图旋转角度
		pitch: 50, //地图仰角 60
		center: [120.603447, 29.815972],
		mapStyle: 'amap://styles/1f65c424888bfb721cfe6a4601c7fdeb', //设置地图
		showBuildingBlock: true, // 显示全局楼宇
		showLabel: false, //显示全局名称
		resizeEnable: true, //全局设置尺寸
		// skyColor: '#fff', //天空颜色
		//eventSupport: true,
	});
	// window.map.addControl(new AMap.MapType({
	//             defaultType:0, //0代表默认，1代表卫星
	//             showRoad:true//显示路况（右上角也可点击）
	// }));
	// ------------------------------------------------------------------ 楼快模型start
 	var loca = window.loca = new Loca.Container({
		map,
	});
	// 环境光
	loca.ambLight = {
		intensity: 3,
		color: '#f6f6fa',
	};
	// 平行光
	loca.dirLight = {
		intensity: 2.7,
		color: '#ffffff',
		target: [0, 0, 0],
		position: [0, -1, 1],
	};
	// 点光
	loca.pointLight = {
		color: 'rgb(15,19,40)',
		position: [121.5043258, 31.2392241, 2600],
		intensity: 25,
		// 距离表示从光源到光照强度为 0 的位置，0 就是光不会消失。
		distance: 3900,
	};
	var geo = new Loca.GeoJSONSource({
		url: cityJson,
	});
	// console.log('111111', geo);
	var pl = window.pl = new Loca.PolygonLayer({
		zIndex: 120,
		shininess: 10,
		hasSide: true,
		cullface: 'back',
		depth: true,
		zooms: buildingZooms // 设置层级范围 显示3D 建筑楼快
	});

	pl.setSource(geo);
	pl.setStyle({
		height: function(index, feature) {
			return feature.properties.h;
		},
		textureSize: [1000, 820],
		texture: cityImage,
		topColor: '#111',

		lineWidth: 20,
		// 脉冲头颜色
		headColor: (_, feature) => {
			return headColors[feature.properties.type - 1];
		},
		// 脉冲尾颜色
		trailColor: 'rgba(128, 128, 128, 0.5)',
		// 脉冲长度，0.25 表示一段脉冲占整条路的 1/4
		interval: 0.25,
		// 脉冲线的速度，几秒钟跑完整段路
		duration: 5000,

	});
	// var dat = new Loca.Dat();
	//         dat.addLight(loca.ambLight, loca, '环境光');
	//         dat.addLight(loca.dirLight, loca, '平行光');
	//         dat.addLight(loca.pointLight, loca, '点光');
	//         dat.addLayer(pl, '楼块');
	pl.setLoca(loca); 
	// ------------------------------------------------------------------ 楼快模型end
	// ------------------------------------------------------------------ 道路start
	var daolu = new Loca.GeoJSONSource({
	    url: 'https://zhipao.oss-cn-hangzhou.aliyuncs.com/models/shaoxing-daolu.json',
	});
	// console.log(daolu)
	var layer = new Loca.PulseLineLayer({
	    // loca,
	    zIndex: 10,
	    opacity: 1,
	    visible: true,
	    zooms: buildingZooms,
	});
	
	var headColors = ['#EFBB51', '#7F3CFF', '#4CC19B', '#0B5D74', '#E06AC4', '#223F9B', '#F15C1A', '#7A0FA6'];
	
	// console.log('daolu', daolu);
	layer.setSource(daolu);
	layer.setStyle({
	    altitude: 0,
	    lineWidth: 4,
	    // 脉冲头颜色
	    headColor: (_, feature) => {
	        
			return headColors[(Math.floor(Math.random() * 6) + 1) - 1];
			//return headColors[feature.properties.type - 1];
	    },
	    // 脉冲尾颜色
	    trailColor: 'rgba(128, 128, 128, 0.5)',
	    // 脉冲长度，0.25 表示一段脉冲占整条路的 1/4
	    interval: 0.25,
	    // 脉冲线的速度，几秒钟跑完整段路
	    duration: 2000,
	});
	loca.add(layer);
	loca.animate.start();
	// ------------------------------------------------------------------ 道路end
	// ------------------------------------------------------------------ 平面start
	// function initPro(code, dep, index, cityStroke, countyStroke) {
	// 	dep = typeof dep == 'undefined' ? 2 : dep;
	// 	adCode = code;
	// 	depth = dep;

	// 	disProvince && disProvince.setMap(null);

	// 	// $("#titless").html(window.screen.width);
	// 	// if(window.screen.width == 960)
	// 	// {
	// 	// 	window.map.setZoomAndCenter(5,[120.601778,29.595973])
	// 	// }
		
		
	// 	disProvince = new AMap.DistrictLayer.Province({
	// 		zIndex: index,
	// 		adcode: [code],
	// 		depth: dep,
	// 		zooms: regionBlockZooms, // 设置层级范围 显示3D 绍兴区域
	// 		height: 8000,
	// 		styles: {
	// 			'fill': function(properties) {
	// 				// properties为可用于做样式映射的字段，包含
	// 				// NAME_CHN:中文名称
	// 				// adcode_pro
	// 				// adcode_cit
	// 				// adcode
	// 				var adcode = properties.adcode;
	// 				return getColorByAdcode(adcode);
	// 			},
	// 			'province-stroke': 'cornflowerblue', //国境线
	// 			'coastline-stroke': '', //海岸线
	// 			'city-stroke': cityStroke, // 中国地级市边界
	// 			'county-stroke': countyStroke, // 中国区县边界
	// 		}
	// 	});
	// 	disProvince.setMap(map);
	// }
	// var getColorByAdcode = function(adcode) {
	// 	if (!colors[adcode]) {

	// 		if (adcode == 330602) { // 越城区
	// 			colors[adcode] = '#3A66EA';
	// 		} else if (adcode == 330603) { // 柯桥区
	// 			colors[adcode] = '#083395';
	// 		} else if (adcode == 330604) { // 上虞区
	// 			colors[adcode] = '#4794DC';
	// 		} else if (adcode == 330681) { // 诸暨市
	// 			colors[adcode] = '#0D7FD1';
	// 		} else if (adcode == 330683) { // 嵊州市
	// 			colors[adcode] = '#2951C4';
	// 		} else if (adcode == 330624) { // 新昌县
	// 			colors[adcode] = '#4695DC';
	// 		}
	// 	}

	// 	return colors[adcode];
	// };

	// initPro(adCode, depth, 30, '#69fefd', '#69fefd')
	// if(window.screen.width == 960){
	// 	window.lotteryjson.home.data.zoom=8.9
	// 	window.lotteryjson.home.data.center=[120.639576,29.784477]
	// }
	// window.thisShow.plug.upDataMap(window.lotteryjson.home)
	//window.map.setZoomAndCenter(8.8,[120.641636,29.688037])
	// ------------------------------------------------------------------ 平面end
	var getvalues = function(v, value) {
		return v < 23 ? value[8] :
			v < 25 ? value[7] :
			v < 30 ? value[6] :
			v < 33 ? value[5] :
			v < 35 ? value[4] :
			v < 38 ? value[3] :
			v < 40 ? value[2] :
			v < 45 ? value[1] :
			v < 50 ? value[0] : 'green';
	}

	var locaShaoxing = new Loca.Container({
		map,
	});

	locaShaoxing.ambLight = {
		intensity: 0.3,
		color: '#fff',
	};
	locaShaoxing.dirLight = {
		intensity: 0.6,
		color: '#fff',
		target: [0, 0, 0],
		position: [1, -1, 1],
	};
	locaShaoxing.pointLight = {
		color: 'rgb(0,0,0)',
		position: [120.612447, 30.050972, 20000],
		intensity: 3,
		// 距离表示从光源到光照强度为 0 的位置，0 就是光不会消失。
		distance: 114000,
	};
	var geoShaoxing = new Loca.GeoJSONSource({
		url: shaoxingJson,
	});
	// var colors = ['#FFF8B4', '#D3F299', '#9FE084', '#5ACA70', '#00AF53', '#00873A', '#006B31', '#004835', '#003829'].reverse();
	// var colors = ['#97B7FF', '#77A0FF', '#5A8CFF', '#3D78FF', '#1A60FF', '#004DFB', '#0044DF', '#003BBF', '#002F9A'].reverse();
	var colors = ['#002F9A', '#003BBF', '#0044DF', '#004DFB', '#1A60FF', '#3D78FF', '#5A8CFF', '#77A0FF', '#97B7FF', ].reverse();
	var height = [16000, 14000, 12000, 10000, 8000, 6000, 4000, 2000, 1000];
	// var height = [40000, 32000, 24000, 16000, 14000, 12000, 10000, 8000,6000];
	
	
	var plShaoxing = new Loca.PolygonLayer({
		// loca,
		zIndex: 120,
		opacity: 0.8,
		// cullface: 'none',
		shininess: 10,
		hasSide: true,
		
		zooms: regionBlockZooms, // 设置层级范围 显示3D 绍兴区域
	});
	

	plShaoxing.setSource(geoShaoxing);
	plShaoxing.setStyle({
		topColor: function(index, feature) {
			var v = (feature.properties.num / feature.properties.allNum * 10000).toFixed(3);
			return getvalues(v, colors)

		},
		sideTopColor: function(index, feature) {
			var v = (feature.properties.num / feature.properties.allNum * 10000).toFixed(3);
			return getvalues(v, colors)
		},
		sideBottomColor: function(index, feature) {
			var v = (feature.properties.num / feature.properties.allNum * 10000).toFixed(3);
			return getvalues(v, colors)
		},
		height: function(index, feature) {
			var v = (feature.properties.num / feature.properties.allNum * 10000).toFixed(3);
			return getvalues(v, height)
		},
		altitude: 0,
	});
	locaShaoxing.add(plShaoxing);

	// 创建纯文本标记
	var text = new AMap.Text({
		text: '纯文本标记',
		anchor: 'center', // 设置文本标记锚点
		draggable: true,
		cursor: 'pointer',
		angle: 0,
		visible: false,
		offset: [0, -25],
		style: {
			'padding': '5px 10px',
			'margin-bottom': '1rem',
			'border-radius': '.25rem',
			'background-color': 'rgba(0,0,0,0.5)',
			// 'width': '12rem',
			'border-width': 0,
			'box-shadow': '0 2px 6px 0 rgba(255, 255, 255, .3)',
			'text-align': 'center',
			'font-size': '16px',
			'color': '#fff',
		},
	});
	text.setMap(map);
	// 拾取
	map.on('mousemove', (e) => {
		var feat = plShaoxing.queryFeature(e.pixel.toArray());
		if (feat) {
			text.show();
			var health = feat.properties.health;
			var html = '';
			html += feat.properties.name + '<br>'
			html += ' 运动人员人数：' + feat.properties.num + '<br>'
			html += ' 总人数：' + feat.properties.allNum + '<br>'
			html += ' 运动员人数百分比：' + (feat.properties.num / feat.properties.allNum * 100).toFixed(3) + ' %'
			text.setText(html);
			text.setPosition(e.lnglat);

			plShaoxing.setStyle({
				topBorderColor:(e) =>{
					console.log(e);
				},
				topColor: (index, feature) => {
					if (feature === feat) {
						return [65, 123, 255, 0.9];
					}
					var v = (feature.properties.num / feature.properties.allNum * 10000).toFixed(3);
					return getvalues(v, colors)
				},
				sideTopColor: (index, feature) => {
					if (feature === feat) {
						return [65, 123, 255, 0.9];
					}
					var v = (feature.properties.num / feature.properties.allNum * 10000).toFixed(3);
					return getvalues(v, colors)
				},
				sideBottomColor: (index, feature) => {
					if (feature === feat) {
						return [65, 123, 255, 0.9];
					}
					var v = (feature.properties.num / feature.properties.allNum * 10000).toFixed(3);
					return getvalues(v, colors)
				},
				height: function(index, feature) {
					var v = (feature.properties.num / feature.properties.allNum * 10000).toFixed(3);
					return getvalues(v, height)
				},
			});
		} else {
			text.hide();
		}
	});
/*	var dat = new Loca.Dat();
	      dat.addLight(locaShaoxing.ambLight, locaShaoxing, '环境光');
	      dat.addLight(locaShaoxing.dirLight, locaShaoxing, '平行光');
	      dat.addLight(locaShaoxing.pointLight, locaShaoxing, '点光');
	      dat.addLayer(plShaoxing);*/
	// 生长动画
	// plShaoxing.addAnimate({
	// 	key: 'height',
	// 	value: [0, 1],
	// 	duration: 1000,
	// 	easing: 'CubicInOut',
	// }, function() {
	// 	// console.log(123);
	// });
	// map.on('click', function(e) {
	// 	console.log(e);
	// 	plShaoxing.addAnimate({
	// 		key: 'height',
	// 		value: [0, 1],
	// 		duration: 1000,
	// 		easing: 'CubicInOut',
	// 	}, function() {
	// 		// console.log(123);
	// 	});
	// });
	
	if(window.screen.width == 960){
		window.lotteryjson.home.data.center = [120.639197,29.811186]
		window.lotteryjson.home.data.zoom = 1
		window.lotteryjson.home.data.zooms = [0, 10]
	}
	
	window.thisShow.plug.upDataMap(window.lotteryjson.home)
	
}
window.clearMap = () => {
	window.map.clearMap()
}
window.loadModel = (obj) => {
			var setObj = {
				title:obj.title,//标题
				objUrl:obj.objUrl,//模型obj路径
				objMtl:obj.objMtl,//模型mtl
				rotateX:obj.rotateX,//模型旋转的角度
				rotateY:obj.rotateY,//模型旋转的角度
				rotateZ:obj.rotateZ,//模型旋转的角度
				objScale:obj.objScale,//物体的局部缩放。默认值是Vector3( 1, 1, 1 )
				objPosition:obj.objPosition, //3d模型定位
				mapPosition:obj.mapPosition,//坐标位置
			};
			console.log(setObj)
			var modelName = setObj.title;
			var scope = this;
			var objLoader = new THREE.OBJLoader2();
			var callbackOnLoad = function ( event ) {
	          	var object3Dlayer = new AMap.Object3DLayer();
		        var meshes = event.detail.loaderRootNode.children;
		        for(var i=0;i<meshes.length;i++){
		            var vecticesF3 = meshes[i].geometry.attributes.position;
		            var vecticesNormal3 = meshes[i].geometry.attributes.normal;
		            var vecticesUV2 = meshes[i].geometry.attributes.uv;
		            
		            var vectexCount =  vecticesF3.count;

		            MapObject3D = new AMap.Object3D.MeshAcceptLights();

		            var geometry = MapObject3D.geometry;
		           
		            //底部一圈
		            // debugger

		            var c,opacity;

		            var material = meshes[i].material[0]||meshes[i].material;
		            // debugger
		            if(material.map)
		            MapObject3D.textures.push('')
		            
		            c = material.color;
		            opacity = material.opacity
		            
		            // debugger
		            for(var j=0;j<vectexCount;j+=1){
		                var s = j*3;
		                geometry.vertices.push(vecticesF3.array[s],vecticesF3.array[s+2],-vecticesF3.array[s+1]);
		               
		                if(vecticesNormal3) {
		               		geometry.vertexNormals.push(vecticesNormal3.array[s],vecticesNormal3.array[s+2],-vecticesNormal3.array[s+1]);
		                }
		                if(vecticesUV2) {
		               		geometry.vertexUVs.push(vecticesUV2.array[j*2],1-vecticesUV2.array[j*2+1]);
		                }
		                geometry.vertexColors.push(c.r,c.g,c.b,opacity)
		            }
		            // debugger
		            MapObject3D.DEPTH_TEST = material.depthTest
		            // MapObject3D.backOrFront = 'both'
		            MapObject3D.transparent = opacity<1;
		            MapObject3D.scale(setObj.objScale[0],setObj.objScale[1],setObj.objScale[2])
		            MapObject3D.rotateZ(setObj.rotateZ)
					MapObject3D.rotateX(setObj.rotateX)
					MapObject3D.rotateY(setObj.rotateY)
					
		            MapObject3D.position(new AMap.LngLat(setObj.objPosition[0],setObj.objPosition[1]),setObj.objPosition[2],setObj.objPosition[3])
					//MapObject3D.position(new AMap.LngLat(120.588667,30.048099),650,0)
					
		            object3Dlayer.add(MapObject3D)
		        }
	          	window.map.add(object3Dlayer)
			};

			var onLoadMtl = function ( materials ) {
				// for(var i=0;i<materials.length;i+=1){
				// 	materials[i].side=2;
				// }
				objLoader.setModelName( modelName );
				objLoader.setMaterials( materials );
				objLoader.load( setObj.objUrl, callbackOnLoad, null, null, null, false );
			};
			objLoader.loadMtl( setObj.objMtl, null, onLoadMtl );
			new AMap.Text({
				text:setObj.title,
				position:setObj.mapPosition,
				height:650,
				verticalAlign:'bottom',
				map:window.map,
				style:{
					'background-color':'rgb(0 0 0 / 39%)',
					'border-color':'rgb(0 0 0 / 39%)',
					'font-size':'16px',
					'color':'#fff'
				}
			})
		}
		
// loadModel({
// 	title: "绍兴科技馆", //标题
// 	objUrl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/kjg.obj", //模型obj路径
// 	objMtl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/kjg.mtl", //模型mtl
// 	objPosition: [120.588667, 30.047599, 700, 0], //3d模型定位
// 	mapPosition: [120.584647, 30.048099], //坐标位置
// 	objScale: [18, 18, 18],
// 	rotateX: 0,
// 	rotateY: 0,
// 	rotateZ: 18,
// })
// loadModel({
// 	title: "元培中学", //标题
// 	objUrl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/yuanpeizhongxue.obj", //模型obj路径
// 	objMtl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/yuanpeizhongxue.mtl", //模型mtl
// 	objPosition: [120.563741, 30.004446, 50, 0], //3d模型定位
// 	mapPosition: [120.564241, 30.005346], //坐标位置
// 	objScale: [0.008, 0.008, 0.008],
// 	rotateX: 0,
// 	rotateY: 0,
// 	rotateZ: 0,
// })
// loadModel({
// 	title: "御景华庭", //标题
// 	objUrl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/yujinghuating.obj", //模型obj路径
// 	objMtl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/yujinghuating.mtl", //模型mtl
// 	objPosition: [120.600286, 30.005568, 10, 0], //3d模型定位
// 	mapPosition: [120.600586, 30.006168], //坐标位置
// 	objScale: [0.015, 0.015, 0.015],
// 	rotateX: 0,
// 	rotateY: 0,
// 	rotateZ: 0,
// })
// loadModel({
// 	title: "阳明中学", //标题
// 	objUrl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/yangmingzhongxue.obj", //模型obj路径
// 	objMtl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/yangmingzhongxue.mtl", //模型mtl
// 	objPosition: [120.550015, 30.004605, 10, 0], //3d模型定位
// 	mapPosition: [120.553585, 30.006905], //坐标位置
// 	objScale: [0.01, 0.01, 0.01],
// 	rotateX: 0,
// 	rotateY: 0,
// 	rotateZ: 5,
// })
// loadModel({
// 	title: "轻纺城", //标题
// 	objUrl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/qingfangcheng.obj", //模型obj路径
// 	objMtl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/qingfangcheng.mtl", //模型mtl
// 	objPosition: [120.487567,30.059518, 10, 0], //3d模型定位
// 	mapPosition: [120.489267,30.059518], //坐标位置
// 	objScale: [0.004, 0.004, 0.004],
// 	rotateX: 0,
// 	rotateY: 0,
// 	rotateZ: 30,
// })
// loadModel({
// 	title: "陆游故里", //标题
// 	objUrl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/luyouguli.obj", //模型obj路径
// 	objMtl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/luyouguli.mtl", //模型mtl
// 	objPosition: [120.527558,30.014281, 10, 0], //3d模型定位
// 	mapPosition: [120.529758,30.014281], //坐标位置
// 	objScale: [0.004, 0.004, 0.004],
// 	rotateX: 0,
// 	rotateY: 0,
// 	rotateZ: 35,
// })
// loadModel({
// 	title: "鲁迅故里建筑群", //标题
// 	objUrl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/luxungulijianzhuqun.obj", //模型obj路径
// 	objMtl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/luxungulijianzhuqun.mtl", //模型mtl
// 	objPosition: [120.578178,29.989213, 10, 0], //3d模型定位
// 	mapPosition: [120.584378,29.993213], //坐标位置
// 	objScale: [0.005, 0.005, 0.005],
// 	rotateX: 0,
// 	rotateY: 0,
// 	rotateZ: 0,
// })
// // TODO 有问题
// loadModel({
// 	title: "府山公园", //标题
// 	objUrl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/fushangongyuan.obj", //模型obj路径
// 	objMtl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/fushangongyuan.mtl", //模型mtl
// 	objPosition: [120.574759,29.999658, 10, 0], //3d模型定位
// 	mapPosition: [120.574759,29.999558], //坐标位置
// 	objScale: [10,10,10],
// 	rotateX: 0,
// 	rotateY: 0,
// 	rotateZ: 0,
// })
// // TODO 有问题
// loadModel({
// 	title: "鲁迅故里", //标题
// 	objUrl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/luxunguli.obj", //模型obj路径
// 	objMtl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/luxunguli.mtl", //模型mtl
// 	objPosition: [120.586733,29.992077, 10, 0], //3d模型定位
// 	mapPosition: [120.586733,29.992077], //坐标位置
// 	objScale: [100,100,100],
// 	rotateX: 0,
// 	rotateY: 0,
// 	rotateZ: -90,
// })
// loadModel({
// 	title: "柯岩风景区", //标题
// 	objUrl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/keyanfengjingqu.obj", //模型obj路径
// 	objMtl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/keyanfengjingqu.mtl", //模型mtl
// 	objPosition: [120.470893,30.049621, 10, 0], //3d模型定位
// 	mapPosition: [120.470893,30.049621], //坐标位置
// 	objScale: [100,100,100],
// 	rotateX: 0,
// 	rotateY: 0,
// 	rotateZ: -90,
// })
// loadModel({
// 	title: "绍兴市体育会展馆", //标题
// 	objUrl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/tiyuguan.obj", //模型obj路径
// 	objMtl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/tiyuguan.mtl", //模型mtl
// 	objPosition: [120.575148,30.055685, -690, 0], //3d模型定位
// 	mapPosition: [120.575148,30.055685], //坐标位置
// 	objScale: [12,12, 12],
// 	rotateX: 0,
// 	rotateY: 0,
// 	rotateZ: 45,
// })
// loadModel({
// 	title: "绍兴市羽毛球馆", //标题
// 	objUrl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/yumaoqiuguan.obj", //模型obj路径
// 	objMtl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/yumaoqiuguan.mtl", //模型mtl
// 	objPosition: [120.575088,30.053812, -690, 0], //3d模型定位
// 	mapPosition: [120.574718,30.054812], //坐标位置
// 	objScale: [12,12, 12],
// 	rotateX: 0,
// 	rotateY: 0,
// 	rotateZ: 45,
// })
// loadModel({
// 	title: "游泳馆", //标题
// 	objUrl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/youyongguan.obj", //模型obj路径
// 	objMtl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/youyongguan.mtl", //模型mtl
// 	objPosition: [120.574505,30.051061, 1700, 0], //3d模型定位
// 	mapPosition: [120.575405,30.051061], //坐标位置
// 	objScale: [20,20, 20],
// 	rotateX: 0,
// 	rotateY: 0,
// 	rotateZ: 0,
// })
// loadModel({
// 	title: "绍兴环球纽约国际中心", //标题
// 	objUrl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/huanqiuniuyueguojizhongxin.obj", //模型obj路径
// 	objMtl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/huanqiuniuyueguojizhongxin.mtl", //模型mtl
// 	objPosition: [120.602315,30.007833, -80, 0], //3d模型定位
// 	mapPosition: [120.602015,30.005433], //坐标位置
// 	objScale: [500,500, 500],
// 	rotateX: 0,
// 	rotateY: 0,
// 	rotateZ: 95,
// })
// loadModel({
// 	title: "绍兴世茂天际中心", //标题
// 	objUrl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/shaoxingshimaotianjizhongxin.obj", //模型obj路径
// 	objMtl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/shaoxingshimaotianjizhongxin.mtl", //模型mtl
// 	objPosition: [120.603539,30.004218, 0, 0], //3d模型定位
// 	mapPosition: [120.603539,30.004218], //坐标位置
// 	objScale: [400,400, 400],
// 	rotateX: 0,
// 	rotateY: 0,
// 	rotateZ: -24,
// })
// loadModel({
// 	title: "镜湖大桥", //标题
// 	objUrl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/jinghudaqiao.obj", //模型obj路径
// 	objMtl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/jinghudaqiao.mtl", //模型mtl
// 	objPosition: [120.577358,30.039207, -120, 0], //3d模型定位
// 	mapPosition: [120.579458,30.041207], //坐标位置
// 	objScale: [500,500, 500],
// 	rotateX: 0,
// 	rotateY: 0,
// 	rotateZ: -24,
// })
// loadModel({
// 	title: "绍兴博物馆", //标题
// 	objUrl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/shaoxingbowuguan.obj", //模型obj路径
// 	objMtl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/shaoxingbowuguan.mtl", //模型mtl
// 	objPosition: [120.572127,29.997623, 0, 0], //3d模型定位
// 	mapPosition: [120.572327,29.997923], //坐标位置
// 	objScale: [60,60, 60],
// 	rotateX: 0,
// 	rotateY: 0,
// 	rotateZ: -24,
// })
// // TODO 有问题的
// loadModel({
// 	title: "文化馆", //标题
// 	objUrl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/wenhuaguan.obj", //模型obj路径
// 	objMtl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/wenhuaguan.mtl", //模型mtl
// 	objPosition: [120.582185,30.048362, 0, 0], //3d模型定位
// 	mapPosition: [120.582185,30.048362], //坐标位置
// 	objScale: [300,300, 300],
// 	rotateX: 0,
// 	rotateY: 0,
// 	rotateZ: 0,
// })
